import { startOfWeek } from 'date-fns';
import firebase from 'firebase';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
	Activity,
	UserDetails,
	lowercaseDayString,
} from '../../../../../constants/Common';
import {
	NoteDay,
	NotesByDay,
	TimesheetNote,
} from '../../../../../constants/Note';
import { Timesheet } from '../../../../../constants/Timesheet/Timesheet';
import { formatActivityDisplayDay } from '../../../../helpers/dateFormatters';
import { getDayOfWeekDate } from '../../../../helpers/dateUtilities';
import { createWeekOrAllWithDefault } from '../timesheetTableUtilities';
import { EditActions, EditActionsTypes } from './actions';

type UpdatedNote = {
	isNew: boolean;
	initialNote: string;
} & Pick<TimesheetNote, 'id' | 'day' | 'note' | 'user'>;

type UpdatedActivity = {
	isNew: boolean; // new
	initialActivity: Pick<Activity, 'hours' | 'week'> & {
		activity: Activity['activity'] | null;
	};
} & Omit<Activity, 'activity'> & {
		activity: Activity['activity'] | null; // null deleted or temp new
	};

export type EditState = {
	timesheetEdited: boolean;
	duplicateTimesheet: string | null;
	updatedTimesheet: Timesheet;
	updatedActivities: Record<string, UpdatedActivity>;
	activityErrors: Record<string, true>;
	notesEdited: boolean;
	updatedNotes: Record<NoteDay, Record<string, UpdatedNote>>;
};

const initNotesState = (
	userDetails: Pick<UserDetails, 'userID' | 'displayName'>,
	notes: NotesByDay,
): Record<NoteDay, Record<string, UpdatedNote>> => {
	const initialNotes = Object.entries(notes).reduce(
		(acc, [day, dayNotes]) => {
			const noteDay = day as NoteDay;
			dayNotes.forEach((note) => {
				acc[noteDay][note.id] = {
					isNew: false,
					id: note.id,
					day: note.day,
					note: note.note,
					initialNote: note.note,
					user: note.user,
				};
			});
			// Add a new temp note if the user doesn't have one
			if (dayNotes.every((note) => note.user.id !== userDetails.userID)) {
				acc[noteDay][`temp-note-${noteDay}`] = {
					isNew: true,
					id: `temp-note-${noteDay}`,
					day: noteDay,
					note: '',
					initialNote: '',
					user: {
						id: userDetails.userID,
						name: userDetails.displayName,
					},
				};
			}
			return acc;
		},
		createWeekOrAllWithDefault<Record<string, UpdatedNote>>({}),
	);

	return initialNotes;
};

const initActivitiesState = (
	activities: Record<string, Activity>,
): Record<string, UpdatedActivity> => {
	const initialActivities = Object.entries(activities).reduce<
		Record<string, UpdatedActivity>
	>((acc, [id, activity]) => {
		acc[id] = {
			...activity,
			isNew: false,
			initialActivity: {
				activity: activity.activity,
				hours: activity.hours,
				week: activity.week,
			},
		};
		return acc;
	}, {});

	return initialActivities;
};

export const createInitialEditState = (initArg: {
	userDetails: Pick<UserDetails, 'userID' | 'displayName'>;
	timesheet: Timesheet;
	activities: Record<string, Activity>;
	notes: NotesByDay;
}): EditState => ({
	timesheetEdited: false,
	duplicateTimesheet: null,
	updatedTimesheet: initArg.timesheet,
	updatedActivities: initActivitiesState(initArg.activities),
	activityErrors: {},
	notesEdited: false,
	updatedNotes: initNotesState(initArg.userDetails, initArg.notes),
});

export const editTimesheetReducer = (
	state: EditState,
	action: EditActions,
): EditState => {
	switch (action.type) {
		case EditActionsTypes.ADD_ACTIVITY: {
			const date = getDayOfWeekDate(
				state.updatedTimesheet.week.toDate(),
				action.payload.day,
			);
			const tempID = uuidv4();
			const newActivity: UpdatedActivity = {
				id: `new-activity-${tempID}`,
				activity: null,
				isNew: true,
				initialActivity: {
					activity: null,
					hours: 0,
					week: state.updatedTimesheet.week,
				},
				date: firebase.firestore.Timestamp.fromDate(date),
				day: action.payload.day,
				displayDay: formatActivityDisplayDay(date),
				employerID: state.updatedTimesheet.employer.id,
				employerName: state.updatedTimesheet.employer.name,
				hours: 0,
				rate: state.updatedTimesheet.contract.chargeOutRate,
				finalReviewAt: null,
				finalReviewBy: null,
				siteCompany: state.updatedTimesheet.site.company,
				siteCompanyID: state.updatedTimesheet.site.companyID,
				siteID: state.updatedTimesheet.site.id,
				siteName: state.updatedTimesheet.site.name,
				status: state.updatedTimesheet.timesheetStatus,
				timesheetID: state.updatedTimesheet.id,
				totalCost: 0,
				week: state.updatedTimesheet.week,
				weekEnding: state.updatedTimesheet.weekEnding,
				workerID: state.updatedTimesheet.employee.id,
				workerName: state.updatedTimesheet.employee.name,
			};

			return {
				...state,
				updatedActivities: {
					...state.updatedActivities,
					[newActivity.id]: newActivity,
				},
			};
		}
		case EditActionsTypes.DELETE_ACTIVITY: {
			const deletedActivity = state.updatedActivities[action.payload.id];

			const updatedTimesheet = cloneDeep(state.updatedTimesheet);
			const hoursField = lowercaseDayString(deletedActivity.day);
			updatedTimesheet.hours[hoursField].billable -=
				deletedActivity.hours;
			updatedTimesheet.hours.total.billable -= deletedActivity.hours;

			updatedTimesheet.cost.billable =
				updatedTimesheet.contract.chargeOutRate *
				updatedTimesheet.hours.total.billable;

			const updatedActivities = cloneDeep(state.updatedActivities);
			if (deletedActivity.isNew) {
				delete updatedActivities[deletedActivity.id];
			} else {
				updatedActivities[deletedActivity.id] = {
					...deletedActivity,
					activity: null,
					hours: 0,
				};
			}

			const updatedErrors = cloneDeep(state.activityErrors);
			delete updatedErrors[deletedActivity.id];

			return {
				...state,
				timesheetEdited: true,
				updatedTimesheet: updatedTimesheet,
				updatedActivities: updatedActivities,
				activityErrors: updatedErrors,
			};
		}
		case EditActionsTypes.UPDATE_ACTIVITY: {
			const originalActivity = state.updatedActivities[action.payload.id];

			const updatedTimesheet = cloneDeep(state.updatedTimesheet);
			const updatedActivity = cloneDeep(originalActivity);
			updatedActivity.activity = action.payload.activity;
			updatedActivity.hours = action.payload.hours;
			updatedActivity.totalCost =
				updatedTimesheet.contract.chargeOutRate * action.payload.hours;

			const hoursField = lowercaseDayString(updatedActivity.day);
			const hoursDifference =
				updatedActivity.hours - originalActivity.hours;
			updatedTimesheet.hours[hoursField].billable += hoursDifference;
			updatedTimesheet.hours.total.billable += hoursDifference;

			updatedTimesheet.cost.billable =
				updatedTimesheet.contract.chargeOutRate *
				updatedTimesheet.hours.total.billable;

			const updatedErrors = cloneDeep(state.activityErrors);
			delete updatedErrors[originalActivity.id];

			return {
				...state,
				timesheetEdited: true,
				updatedTimesheet: updatedTimesheet,
				updatedActivities: {
					...state.updatedActivities,
					[updatedActivity.id]: updatedActivity,
				},
				activityErrors: updatedErrors,
			};
		}
		case EditActionsTypes.UPDATE_BREAKS: {
			const updatedTimesheet = cloneDeep(state.updatedTimesheet);
			const hoursField = lowercaseDayString(action.payload.day);
			const existingBreak =
				state.updatedTimesheet.hours[hoursField].break;
			updatedTimesheet.hours[hoursField].break = action.payload.hours;
			updatedTimesheet.hours.total.break +=
				-existingBreak + action.payload.hours;

			return {
				...state,
				timesheetEdited: true,
				updatedTimesheet: updatedTimesheet,
			};
		}
		case EditActionsTypes.UPDATE_WEEK: {
			const newWeekEnding = firebase.firestore.Timestamp.fromDate(
				action.payload.weekEnding,
			);
			const newWeekDate = startOfWeek(action.payload.weekEnding);
			const newWeek = firebase.firestore.Timestamp.fromDate(newWeekDate);
			const updatedActivities = cloneDeep(state.updatedActivities);

			Object.values(updatedActivities).forEach((activity) => {
				activity.weekEnding = newWeekEnding;
				activity.week = newWeek;
				const newDate = getDayOfWeekDate(newWeekDate, activity.day);
				activity.date = firebase.firestore.Timestamp.fromDate(newDate);
				activity.displayDay = formatActivityDisplayDay(newDate);
			});

			return {
				...state,
				timesheetEdited: true,
				updatedTimesheet: {
					...state.updatedTimesheet,
					week: newWeek,
					weekEnding: newWeekEnding,
				},
				updatedActivities: updatedActivities,
			};
		}

		case EditActionsTypes.UPDATE_NOTE: {
			const { day, id, note } = action.payload;
			const newUpdatedNotes = cloneDeep(state.updatedNotes);
			newUpdatedNotes[day][id].note = note;

			return {
				...state,
				notesEdited: true,
				updatedNotes: newUpdatedNotes,
			};
		}
		case EditActionsTypes.UPDATE_TIMESHEET_ERRORS: {
			return {
				...state,
				activityErrors: action.payload.errors,
			};
		}
	}
};

export const isNewNote = (note: UpdatedNote): boolean =>
	note.isNew && note.note.trim() !== '';

export const isDeletedNote = (note: UpdatedNote): boolean =>
	!note.isNew && note.note.trim() === '';

export const isEditedNote = (note: UpdatedNote): boolean =>
	!note.isNew && note.initialNote !== note.note.trim();

export const isNewActivity = (
	activity: Pick<UpdatedActivity, 'isNew' | 'activity'>,
): boolean => activity.isNew && activity.activity !== null;

export const isDeletedActivity = (
	activity: Pick<UpdatedActivity, 'isNew' | 'activity'>,
): boolean => !activity.isNew && activity.activity === null;

export const isUpdatedActivity = (
	activity: Pick<
		UpdatedActivity,
		'isNew' | 'activity' | 'initialActivity' | 'hours' | 'week'
	>,
): boolean =>
	!activity.isNew &&
	activity.activity !== null &&
	(!isEqual(activity.activity, activity.initialActivity.activity) ||
		activity.hours !== activity.initialActivity.hours ||
		!isEqual(activity.week, activity.initialActivity.week));

/**
 * Asserts that the activity is not null.
 * @throws {TypeError} if the activity is null
 *
 * @ignore Note must use function syntax not an arrow function
 */
function assertsIsNonNullActivity(
	activityType: Activity['activity'] | null,
): asserts activityType is Activity['activity'] {
	if (activityType === null) {
		throw new TypeError('Activity is null');
	}
}

export const updatedActivityConverter = (
	activity: UpdatedActivity,
): Activity => {
	// Filters will ensure activity is not null, but this'll confirm it for TS
	assertsIsNonNullActivity(activity.activity);

	return {
		id: activity.id,
		activity: activity.activity,
		hours: activity.hours,
		date: activity.date,
		timesheetID: activity.timesheetID,
		day: activity.day,
		displayDay: activity.displayDay,
		employerID: activity.employerID,
		siteID: activity.siteID,
		employerName: activity.employerName,
		rate: activity.rate,
		status: activity.status,
		totalCost: activity.totalCost,
		week: activity.week,
		weekEnding: activity.weekEnding,
		workerID: activity.workerID,
		workerName: activity.workerName,
		siteCompany: activity.siteCompany,
		siteCompanyID: activity.siteCompanyID,
		siteName: activity.siteName,
		finalReviewBy: activity.finalReviewBy,
		finalReviewAt: activity.finalReviewAt,
	};
};
