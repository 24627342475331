import Close from '@mui/icons-material/Close';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FixMeLater } from '../../constants/AnyTypes';
import {
	AccountType,
	AccountTypes,
	Company,
	CompanyTypes,
	Site,
	SiteStatus,
	UserDetails,
} from '../../constants/Common';
import { Persona } from '../../constants/Personas';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { sortObjectByField } from '../helpers/sortHelpers';
import { AdminPersonas } from './AdminPersonas';

type AdminAccountTypeDetails = {
	accountType: AccountType;
	companyID: string;
	siteID: string;
	contractedToCompanyID: string;
};

export type AdminChangeAccountDetailsProps = {
	userDetails: UserDetails | null;
	firebaseApi: Pick<
		FirebaseApi,
		| 'getActiveSites'
		| 'getCompanies'
		| 'adminUpdateUser'
		| 'createPersona'
		| 'personasByUserID'
		| 'deletePersona'
	>;
};

export const AdminChangeAccountDetails = ({
	userDetails,
	firebaseApi,
}: AdminChangeAccountDetailsProps): JSX.Element => {
	const theme = useTheme();
	const defaultDetails = {
		accountType: userDetails?.accountType ?? '',
		companyID: userDetails?.companyID ?? '',
		siteID: userDetails?.siteID ?? '',
		contractedToCompanyID: userDetails?.contractedTo?.id ?? '',
	};
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [companies, setCompanies] = useState<Record<string, Company>>({});
	const [modalOpen, setModalOpen] = useState(false);
	const [newAccountInfo, setNewAccountInfo] =
		useState<AdminAccountTypeDetails>(defaultDetails);
	const [personaName, setPersonaName] = useState<string>('');
	const [nameError, setNameError] = useState<string>('');
	const [savingPersona, setSavingPersona] = useState(false);
	const mountedRef = useRef(true);

	useEffect(() => {
		mountedRef.current = true;
		const getSites = async (): Promise<void> => {
			const siteDocs = await firebaseApi.getActiveSites();
			const siteList: Record<string, Site> = {
				'': {
					id: '',
					address: '',
					allowAppSignIn: false,
					autoSignOutTime: -1,
					city: '',
					company: '',
					companyID: '',
					companyType: CompanyTypes.construction,
					createdBy: '',
					createdByID: '',
					jobNumber: '',
					name: '',
					region: '',
					showCovidWarning: false,
					siteContact: '',
					siteContactNumber: '',
					status: SiteStatus.Active,
					timesheetActivitiesV2: [],
					startTime: 0,
					hasInductions: false,
					safetyCourseRequiredForInduction: false,
				},
			};
			siteDocs.forEach((docSnapshot) => {
				const siteData = docSnapshot.data();
				siteList[siteData.id] = siteData;
			});
			const sortedSites = sortObjectByField(siteList, 'name');
			if (mountedRef.current) setSites(sortedSites);
		};
		const getCompanies = async (): Promise<void> => {
			const companiesMap = await firebaseApi.getCompanies();
			companiesMap[''] = {
				name: '',
				id: '',
				companyType: CompanyTypes.construction,
			};
			const sortedCompanies = sortObjectByField(companiesMap, 'name');
			if (mountedRef.current) setCompanies(sortedCompanies);
		};
		getSites();
		getCompanies();
		return () => {
			mountedRef.current = false;
		};
	}, [firebaseApi]);

	const openModal = (): void => {
		setModalOpen(!modalOpen);
	};

	const handleSaveDetails = async (
		accountInfo: AdminAccountTypeDetails,
	): Promise<void> => {
		if (
			userDetails?.userID &&
			// Only update userDetails if a change has been made
			(accountInfo.accountType !== userDetails.accountType ||
				accountInfo.companyID !== userDetails.companyID ||
				accountInfo.contractedToCompanyID !==
					(userDetails.contractedTo?.id ?? null) ||
				accountInfo.siteID !== userDetails.siteID)
		) {
			const updatedUserDetails: Pick<
				UserDetails,
				| 'accountType'
				| 'site'
				| 'siteID'
				| 'siteCompany'
				| 'siteCompanyID'
				| 'company'
				| 'companyID'
				| 'contractedTo'
			> = {
				accountType: accountInfo.accountType,
				site: sites[accountInfo.siteID].name,
				siteID: accountInfo.siteID,
				siteCompany: sites[accountInfo.siteID].company,
				siteCompanyID: sites[accountInfo.siteID].companyID,
				company: companies[accountInfo.companyID].name,
				companyID: accountInfo.companyID,
				contractedTo:
					accountInfo.contractedToCompanyID === ''
						? null
						: {
								name: companies[
									accountInfo.contractedToCompanyID
								].name,
								id: accountInfo.contractedToCompanyID,
						  },
			};
			await firebaseApi.adminUpdateUser(
				userDetails.userID,
				updatedUserDetails,
			);
		}
		setModalOpen(false);
	};

	const handleAccountTypeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		const user: AdminAccountTypeDetails = { ...newAccountInfo };
		user.accountType = event.target.value as AccountType;
		setNewAccountInfo(user);
	};

	const handleCompanySiteChange =
		(parameterName: 'companyID' | 'siteID' | 'contractedToCompanyID') =>
		(_: React.SyntheticEvent, value: string | null) => {
			const user: AdminAccountTypeDetails = { ...newAccountInfo };
			user[parameterName] = value ?? '';
			setNewAccountInfo(user);
		};

	const handleSavePersona = async (): Promise<void> => {
		if (!userDetails?.userID) {
			return;
		} else if (personaName.trim() === '') {
			setNameError('Please enter a persona name');
			return;
		}
		setSavingPersona(true);
		await firebaseApi.createPersona(userDetails.userID, {
			name: personaName,
			accountType: newAccountInfo.accountType,
			company: {
				name: companies[newAccountInfo.companyID].name,
				id: newAccountInfo.companyID,
			},
			site: {
				name: sites[newAccountInfo.siteID].name,
				id: newAccountInfo.siteID,
			},
			contractedTo: newAccountInfo.contractedToCompanyID
				? {
						name: companies[newAccountInfo.contractedToCompanyID]
							.name,
						id: newAccountInfo.contractedToCompanyID,
				  }
				: null,
		});
		setPersonaName('');
		setSavingPersona(false);
	};

	const handlePersonaOnClick = (persona: Persona): void => {
		const accountInfo = {
			companyID: persona.company.id,
			accountType: persona.accountType,
			siteID: persona.site.id,
			contractedToCompanyID: persona.contractedTo?.id ?? '',
		};
		handleSaveDetails(accountInfo);
		setNewAccountInfo(accountInfo);
	};

	const companyAutoComplete = (changeCompany: boolean): JSX.Element => (
		<Autocomplete
			options={Object.keys(companies)}
			getOptionLabel={(option): string => companies[option]?.name ?? ''}
			fullWidth
			onChange={handleCompanySiteChange(
				changeCompany ? 'companyID' : 'contractedToCompanyID',
			)}
			value={
				changeCompany
					? companies[newAccountInfo.companyID]?.id ?? ''
					: companies[newAccountInfo.contractedToCompanyID]?.id ?? ''
			}
			isOptionEqualToValue={(option, value): boolean => option === value}
			renderOption={(props: FixMeLater, option: string): JSX.Element => (
				<MenuItem
					{...props}
					label={companies[option].name}
					variant="standard"
					key={option}>
					{option === '' ? 'No Company' : companies[option].name}
				</MenuItem>
			)}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label={changeCompany ? 'Company' : 'Contracted To'}
					inputProps={{
						...params.inputProps,
					}}
					FormHelperTextProps={{
						style: { userSelect: 'text', pointerEvents: 'auto' },
					}}
				/>
			)}
		/>
	);

	const siteAutoComplete = (
		<Autocomplete
			options={Object.keys(sites)}
			getOptionLabel={(option): string => sites[option]?.name ?? ''}
			fullWidth
			onChange={handleCompanySiteChange('siteID')}
			value={sites[newAccountInfo.siteID]?.id ?? ''}
			isOptionEqualToValue={(option, value): boolean => option === value}
			renderOption={(props: FixMeLater, option: string): JSX.Element => (
				<MenuItem
					{...props}
					label={sites[option].name}
					variant="standard"
					key={option}>
					{option === '' ? 'No Site' : sites[option].name}
				</MenuItem>
			)}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label="Site"
					inputProps={{
						...params.inputProps,
					}}
				/>
			)}
		/>
	);

	const accountChange = (
		<Grid
			container
			spacing={2}
			padding={2}
			display="flex"
			alignContent="flex-start">
			<Grid item xs={12}>
				<TextField
					value={newAccountInfo.accountType}
					fullWidth
					label="Account Info"
					onChange={handleAccountTypeChange}
					select>
					{Object.keys(AccountTypes).map((accountType) => (
						<MenuItem key={accountType} value={accountType}>
							{accountType === ''
								? 'No Account Type'
								: accountType}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12}>
				{siteAutoComplete}
				{sites[newAccountInfo.siteID]?.id && (
					<Typography variant="caption" pl={2}>
						{sites[newAccountInfo.siteID]?.id}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12}>
				{companyAutoComplete(true)}
				{companies[newAccountInfo.companyID]?.id && (
					<Typography variant="caption" pl={2}>
						{companies[newAccountInfo.companyID]?.id}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12}>
				{companyAutoComplete(false)}
				{companies[newAccountInfo.contractedToCompanyID]?.id && (
					<Typography variant="caption" pl={2}>
						{companies[newAccountInfo.contractedToCompanyID]?.id}
					</Typography>
				)}
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					value={personaName}
					label="Persona Name"
					error={nameError !== ''}
					helperText={nameError}
					onChange={(event): void => {
						if (nameError !== '') setNameError('');
						setPersonaName(event.target.value);
					}}
				/>
			</Grid>
		</Grid>
	);

	const modal = (
		<Dialog
			color="default"
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={openModal}>
			<DialogTitle>
				Change Account Details
				<IconButton
					onClick={(): void => {
						setModalOpen(!modalOpen);
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Stack
					divider={<Divider orientation="vertical" flexItem />}
					direction="row">
					{userDetails?.userID && (
						<AdminPersonas
							firebaseApi={firebaseApi}
							userID={userDetails.userID}
							handlePersonaOnClick={handlePersonaOnClick}
						/>
					)}
					{accountChange}
				</Stack>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					variant="outlined"
					loading={savingPersona}
					onClick={handleSavePersona}>
					Add Persona
				</LoadingButton>
				<Button
					variant="contained"
					onClick={(): Promise<void> =>
						handleSaveDetails(newAccountInfo)
					}>
					Save Account Details
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{modal}
			<Button
				color="inherit"
				sx={{
					textTransform: 'none',
					fontSize: '1rem',
					[theme.breakpoints.down('lg')]: {
						fontSize: '0.7rem',
					},
				}}
				onClick={openModal}
				startIcon={
					<SupervisorAccountIcon
						sx={{
							transform: 'scale(1.5)',
							[theme.breakpoints.down('xs')]: {
								transform: 'scale(0.7)',
							},
						}}
					/>
				}>
				Change Account Details
			</Button>
		</>
	);
};
