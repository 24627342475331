import ApartmentIcon from '@mui/icons-material/Apartment';
import Close from '@mui/icons-material/Close';
import DomainIcon from '@mui/icons-material/Domain';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	Toolbar,
	Typography,
	useTheme,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TradeLegionLogo from '../../src/images/birdonlyblack-transparentbackground.png';
import { AdminChangeAccountDetails } from '../components/AdminChangeAccountDetails/AdminChangeAccountDetails';
import {
	SiteSelectDropdown,
	SiteSelectGroup,
} from '../components/SiteSelectDropdown/SiteSelectDropdown';
import {
	AccountType,
	AccountTypes,
	appDrawerWidth,
	CompanyTypes,
	Site,
	SiteStatus,
	UserDetails,
} from '../constants/Common';
import { DefaultPage, Page } from '../constants/PageConfig';
import firebaseApi, { FirebaseApi } from '../firebase/firebaseApi';
import { useWindowPosition } from '../hooks/useWindowPosition';
import { useFeatureFlagContext } from '../providers/featureFlags/Provider';
import { useUserDetailsContext } from '../providers/UserProvider';

type AppBarProps = MuiAppBarProps & {
	open?: boolean;
};

type HeaderProps = {
	onSignIn: () => void;
	onSignOut: () => void;
	onSignUp: () => void;
	open: boolean;
	handleDrawerOpen: () => void;
	showSignIn: boolean;
	showSignOut: boolean;
	ref?: React.MutableRefObject<HTMLDivElement | null>;
	showDrawer: boolean;
	navigateToDashboard: () => void;
	navigateToAccountSettings: () => void;
	currentPageConfig?: Page | DefaultPage;
	firebaseApi: Pick<
		FirebaseApi,
		'activeSitesSubscription' | 'activeSitesByCompanySubscription'
	>;
};

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop: string) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${appDrawerWidth}px)`,
		marginLeft: `${appDrawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Header = (props: HeaderProps): JSX.Element => {
	const isScrolled = useWindowPosition('root', 'root', 0);
	const featureFlags = useFeatureFlagContext();
	const allowAdminAccountChange = featureFlags.get('adminAccountTypeChange');
	const [modalOpen, setModalOpen] = useState(false);
	const userDetails = useUserDetailsContext();
	const [companyOrSiteDisplay, setCompanyOrSiteDisplay] =
		useState<string>('');
	const [sites, setSites] = useState<Record<string, Site>>({});
	const headerTypes = AccountTypes;
	const theme = useTheme();
	const navigate = useNavigate();

	const checkSiteOrCompany = useCallback(
		(accountType: AccountType) => {
			return headerTypes[accountType].display;
		},
		[headerTypes],
	);

	useEffect(() => {
		if (!userDetails) return;
		const display = checkSiteOrCompany(userDetails.accountType);
		// Want to set siteID as we toggle between them, but company we just display.
		const initialValue =
			display === 'site' ? userDetails.siteID : userDetails.company;
		if (initialValue !== undefined && initialValue !== '')
			setCompanyOrSiteDisplay(initialValue);
		else {
			setCompanyOrSiteDisplay('');
		}

		const sitesCallback = (sites: Record<string, Site>): void => {
			sites[''] = {
				id: '',
				name: 'No Site',
				company: '',
				companyID: '',
				address: '',
				allowAppSignIn: false,
				autoSignOutTime: -1,
				city: '',
				companyType: CompanyTypes.construction,
				createdBy: '',
				createdByID: '',
				jobNumber: '',
				region: '',
				showCovidWarning: false,
				siteContact: '',
				siteContactNumber: '',
				status: SiteStatus.Active,
				timesheetActivitiesV2: [],
				startTime: 0,
				hasInductions: false,
				safetyCourseRequiredForInduction: false,
			};

			setSites(sites);
		};

		const sitesSubscription = headerTypes[userDetails.accountType]
			.filterAllSites
			? props.firebaseApi.activeSitesSubscription(sitesCallback)
			: props.firebaseApi.activeSitesByCompanySubscription(
					userDetails.companyID,
					sitesCallback,
			  );

		return sitesSubscription;
	}, [checkSiteOrCompany, headerTypes, props.firebaseApi, userDetails]);

	const openModal = (): void => {
		setModalOpen(!modalOpen);
	};

	const siteOrCompanyDisplayText = (userDetails: UserDetails): string =>
		checkSiteOrCompany(userDetails.accountType) === 'site'
			? userDetails.siteID !== ''
				? sites[companyOrSiteDisplay]?.name ?? 'Site Not Avaliable'
				: 'No Site Selected'
			: companyOrSiteDisplay;

	const handleSiteChange = async (
		_: React.SyntheticEvent,
		value: Site | null,
	): Promise<void> => {
		if (!value || !userDetails?.userID) return;
		setCompanyOrSiteDisplay(value.id);
		await firebaseApi.changeUserSite(userDetails.userID, {
			siteID: value.id,
			site: value.name === 'No Site' ? '' : value.name,
			siteCompany: value.company,
			siteCompanyID: value.companyID,
			contractedTo: {
				id: value.companyID,
				name: value.company,
			},
		});
		setModalOpen(false);
	};

	const modalBody = (
		<DialogContent>
			<DialogTitle>
				Change Site
				<IconButton
					onClick={(): void => {
						setModalOpen(!modalOpen);
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogActions>
				<SiteSelectDropdown
					value={sites[companyOrSiteDisplay] ?? {}}
					onChange={handleSiteChange}
					sites={sites}
					groupBy={{
						getGroup: (option: Site): SiteSelectGroup =>
							userDetails?.recentSites.some(
								(recentSite) => option.id === recentSite.id,
							)
								? SiteSelectGroup.RecentSites
								: SiteSelectGroup.Sites,
						order: [
							SiteSelectGroup.RecentSites,
							SiteSelectGroup.Sites,
						],
					}}
					error={false}
				/>
			</DialogActions>
		</DialogContent>
	);

	return (
		<>
			<AppBar
				elevation={isScrolled ? 4 : 0}
				position="sticky"
				color="default"
				sx={{
					backgroundColor: theme.palette.backgroundHeader.default,
				}}
				open={props.open && props.showDrawer}>
				<Toolbar sx={{ width: '100%' }}>
					{props.showDrawer ? (
						<IconButton
							size="large"
							color="default"
							edge="start"
							aria-label="menu"
							onClick={props.handleDrawerOpen}
							sx={{
								mr: 2,
								...(props.open && { display: 'none' }),
							}}>
							<MenuIcon />
						</IconButton>
					) : (
						<Button onClick={(): void => navigate('/')}>
							<Box
								component="img"
								src={TradeLegionLogo}
								alt="Trade Legion"
								sx={{ height: 32, margin: 1 }}
							/>
						</Button>
					)}
					<Typography
						variant="h6"
						component="div"
						color="default"
						visibility={
							(isScrolled ||
								props.currentPageConfig?.alwaysShowTitle) &&
							props.currentPageConfig?.name
								? 'visible'
								: 'hidden'
						}
						sx={{
							flexGrow: 1,
							[theme.breakpoints.down('md')]: {
								fontSize: '0.7rem',
							},
						}}>
						Trade Legion
						{props.currentPageConfig &&
						props.currentPageConfig?.name !== 'Home'
							? // this will only show the name of a top level page, even if it contains nested pages,
							  //  but since any alternative behaviour would be weird, so it's fine
							  ` ${props.currentPageConfig?.name}`
							: ''}
					</Typography>
					{props.showSignIn && (
						<Button
							color="inherit"
							size="large"
							sx={{
								mt: '10px',
								'::before': {
									[theme.breakpoints.down('xs')]: {
										mt: '15px',
									},
								},
							}}
							onClick={props.onSignUp}>
							Create Account
						</Button>
					)}
					{props.showSignIn && (
						<Button
							color="inherit"
							size="large"
							sx={{
								mt: '10px',
								'::before': {
									[theme.breakpoints.down('xs')]: {
										mt: '15px',
									},
								},
							}}
							onClick={props.onSignIn}>
							Login
						</Button>
					)}
					{props.showSignOut && (
						<>
							<Stack
								sx={{
									alignItems: 'center',
								}}
								direction="row"
								spacing={1}
								divider={
									<Divider orientation="vertical" flexItem />
								}>
								{allowAdminAccountChange && (
									<AdminChangeAccountDetails
										userDetails={userDetails}
										firebaseApi={firebaseApi}
									/>
								)}
								{!props.showDrawer && (
									<Button
										onClick={props.navigateToDashboard}
										color="inherit"
										sx={{
											fontSize: '1rem',
											[theme.breakpoints.down('md')]: {
												fontSize: '0.7rem',
											},
										}}>
										Go to Dashboard
									</Button>
								)}
								{userDetails?.accountType &&
									!allowAdminAccountChange &&
									headerTypes[userDetails.accountType]
										.filterAllSites !== null && (
										<Button
											color="inherit"
											sx={{
												textTransform: 'none',
											}}
											onClick={openModal}
											startIcon={
												<DomainIcon
													sx={{
														transform: 'scale(1.5)',
														[theme.breakpoints.down(
															'xs',
														)]: {
															transform:
																'scale(0.7)',
														},
													}}
												/>
											}>
											<Typography
												ml={1}
												sx={{
													[theme.breakpoints.down(
														'md',
													)]: {
														visibility: 'hidden',
														display: 'none',
													},
												}}>
												{siteOrCompanyDisplayText(
													userDetails,
												)}
											</Typography>
										</Button>
									)}
								{userDetails?.accountType &&
									headerTypes[userDetails.accountType]
										.filterAllSites === null && (
										<>
											<ApartmentIcon />
											<Typography>
												{siteOrCompanyDisplayText(
													userDetails,
												)}
											</Typography>
										</>
									)}

								<>
									<Button
										sx={{
											transform: 'scale(1)',
											[theme.breakpoints.down('xs')]: {
												transform: 'scale(0.7)',
											},
											textTransform: 'none',
										}}
										color="inherit"
										onClick={(): void =>
											props.navigateToAccountSettings()
										}
										startIcon={
											<Avatar
												src={userDetails?.photoURL}
											/>
										}>
										<Typography
											ml={1}
											sx={{
												[theme.breakpoints.down('md')]:
													{
														display: 'none',
														visibility: 'hidden',
													},
											}}>
											{userDetails?.displayName}
										</Typography>
									</Button>
								</>
								<IconButton
									size="large"
									edge="start"
									color="default"
									aria-label="menu"
									onClick={props.onSignOut}>
									<ExitToAppIcon
										sx={{
											transform: 'scale(1.5)',
											[theme.breakpoints.down('xs')]: {
												transform: 'scale(0.7)',
											},
										}}
									/>
								</IconButton>
							</Stack>
							<Dialog
								color="default"
								fullWidth
								maxWidth="xs"
								open={modalOpen}
								onClose={openModal}>
								{modalBody}
							</Dialog>
						</>
					)}
				</Toolbar>
			</AppBar>
			<Box ref={props.ref} height="0px" />
		</>
	);
};

export default Header;
