import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import {
	Divider,
	Grid,
	Paper,
	Portal,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { SingleTimesheetRequest } from '../../../../cloudfunctions/fetchSingleTimesheet';
import {
	accountTypes,
	Activity,
	SiteLog,
	UserProps,
} from '../../../../constants/Common';
import { NotesByDay } from '../../../../constants/Note';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import { timesheetsListSort } from '../../../../constants/Timesheet/TimesheetUtilities';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { formatSearchParamsDate } from '../../../helpers/dateFormatters';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { getTimesheetActions } from '../../timesheetActions';
import {
	DetailsEventType,
	DetailsSnackbar,
	EventType,
} from './DetailsSnackbar';
import {
	EditTimesheetDialog,
	EditTimesheetDialogFirebaseCalls,
} from './EditTimesheet/EditTimesheetDialog';
import { TimesheetActionsBar } from './TimesheetActionsBar';
import {
	TimesheetDetails,
	TimesheetDetailsFirebaseCalls,
} from './TimesheetDetails';
import { TimesheetsStatusList } from './TimesheetsStatusList';
import { createWeekOrAllWithDefault } from './timesheetTableUtilities';

export type DetailsFirebaseCalls =
	| 'activitySubscription'
	| 'updateTimesheetActivities'
	| 'userSitelogsForWeekSubscription'
	| 'getNoPictureUrl'
	| 'subscribeTimesheetNotesByTimesheetID'
	| EditTimesheetDialogFirebaseCalls
	| TimesheetDetailsFirebaseCalls;

export type DetailsCloudFunctionCalls =
	| 'fetchSingleTimesheet'
	| 'deleteTimesheetNote'
	| 'createTimesheetNote'
	| 'editTimesheetNote';

export type DetailsProps = UserProps & {
	currentTimesheet: Timesheet | null;
	allTimesheets: Timesheet[];
	setTimesheet: (timesheet?: Timesheet) => void;
	canActionTimesheets: boolean;
	loading: boolean;
	downloadFile: (fileURL: string, fileName: string) => void;
	navigateWithoutRouter: (href: string) => void;
	firebaseApi: Pick<FirebaseApi, DetailsFirebaseCalls>;
	cloudFunctionApi: Pick<CloudFunctionApi, DetailsCloudFunctionCalls>;
};

export const Details = ({
	currentTimesheet,
	allTimesheets,
	userDetails,
	user,
	setTimesheet,
	canActionTimesheets,
	loading,
	downloadFile,
	navigateWithoutRouter,
	firebaseApi,
	cloudFunctionApi,
}: DetailsProps): JSX.Element => {
	const theme = useTheme();
	const [searchParams] = useSearchParams();
	const abortSignal = useAbortController();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [activities, setActivities] = useState<Record<string, Activity>>({});
	const [siteLogs, setSitelogs] = useState<SiteLog[]>([]);
	const [defaultSiteLogURL, setDefaultSiteLogURL] = useState<string | null>(
		null,
	);
	const [notes, setNotes] = useState<NotesByDay>(
		createWeekOrAllWithDefault([]),
	);
	const [editTimesheetOpen, setEditTimesheetOpen] = useState<boolean>(false);
	const [itemsLoading, setItemsLoading] = useState({
		activities: true,
		siteLogs: true,
		notes: true,
		defaultSiteLogURL: true,
	});
	const [feedbackSnackbarEvent, setFeedbackSnackbarEvent] =
		useState<EventType | null>(null);

	const usesTimesheetReviewTabName =
		userDetails.accountType === accountTypes.management ||
		userDetails.accountType === accountTypes.seniorManagement;

	useEffect(() => {
		setItemsLoading((prev) => ({
			...prev,
			activities: true,
			siteLogs: true,
			notes: true,
		}));

		if (currentTimesheet && currentTimesheet.id) {
			const activitySub = firebaseApi.activitySubscription(
				currentTimesheet.id,
				(allActivities) => {
					setActivities(allActivities);
					setItemsLoading((prev) => ({ ...prev, activities: false }));
				},
			);

			const siteLogSub = firebaseApi.userSitelogsForWeekSubscription(
				currentTimesheet.employee.id,
				currentTimesheet.site.id,
				currentTimesheet.week,
				(logs) => {
					setSitelogs(logs);
					setItemsLoading((prev) => ({
						...prev,
						siteLogs: false,
					}));
				},
			);

			const notesSub = firebaseApi.subscribeTimesheetNotesByTimesheetID(
				currentTimesheet.id,
				(timesheetNotes) => {
					setNotes(
						timesheetNotes.reduce<NotesByDay>((acc, note) => {
							acc[note.day].push(note);
							return acc;
						}, createWeekOrAllWithDefault([])),
					);
					setItemsLoading((prev) => ({
						...prev,
						notes: false,
					}));
				},
			);

			return () => {
				activitySub();
				siteLogSub();
				notesSub();
			};
		} else {
			setActivities({});
			setSitelogs([]);
			setItemsLoading((prev) => ({
				...prev,
				activities: false,
				siteLogs: false,
				notes: false,
			}));
		}
	}, [currentTimesheet, firebaseApi]);

	useEffect(() => {
		setItemsLoading((prev) => ({
			...prev,
			defaultSiteLogURL: defaultSiteLogURL === null,
		}));
		if (
			currentTimesheet &&
			currentTimesheet.id &&
			defaultSiteLogURL === null
		) {
			firebaseApi.getNoPictureUrl().then((url) => {
				setDefaultSiteLogURL(url);
				setItemsLoading((prev) => ({
					...prev,
					defaultSiteLogURL: false,
				}));
			});
		} else {
			setItemsLoading((prev) => ({
				...prev,
				defaultSiteLogURL: false,
			}));
		}
	}, [currentTimesheet, defaultSiteLogURL, firebaseApi]);

	useEffect(() => {
		if (!currentTimesheet?.timesheetStatus && editTimesheetOpen) {
			setEditTimesheetOpen(false);
		}
	}, [currentTimesheet?.timesheetStatus, editTimesheetOpen]);

	// intentionally DONT reorder timesheets until reload to prevent people losing their place
	const sortedTimesheets = timesheetsListSort(allTimesheets);

	const selectPreviousTimesheet = (): void => {
		if (!currentTimesheet) return;
		const currentIndex = sortedTimesheets.indexOf(currentTimesheet);
		if (currentIndex <= 0) {
			setTimesheet(sortedTimesheets[1]);
		} else {
			setTimesheet(sortedTimesheets[currentIndex - 1]);
		}
	};

	// not sure this function's type is the best, has a lot of work to use it
	const getAction = getTimesheetActions(userDetails, firebaseApi);
	let action: { buttonText: string; onClick: () => Promise<void> } | null =
		null;
	if (
		currentTimesheet &&
		(currentTimesheet.timesheetStatus === TimesheetStatus.Active ||
			currentTimesheet.timesheetStatus == TimesheetStatus.Submitted) &&
		canActionTimesheets
	) {
		const timesheetAction = getAction(currentTimesheet.timesheetStatus);
		const onClick = (): Promise<void> =>
			timesheetAction.onClick(currentTimesheet, activities);

		action = { buttonText: timesheetAction.btnText, onClick };
	}

	const navigateToTimesheet = (
		targetTimesheet: Pick<
			Timesheet,
			'id' | 'weekEnding' | 'timesheetStatus'
		>,
	): void => {
		const params = new URLSearchParams(searchParams);
		params.set(
			'endDate',
			formatSearchParamsDate(targetTimesheet.weekEnding.toDate()),
		);
		params.set('timesheetID', targetTimesheet.id);

		const page =
			targetTimesheet.timesheetStatus === TimesheetStatus.Active
				? 'overview'
				: usesTimesheetReviewTabName
				? 'review'
				: 'details';

		navigateWithoutRouter(`/timesheets/${page}?${params}`);
	};

	const downloadPDF = async (): Promise<void> => {
		if (!currentTimesheet || !user) {
			setFeedbackSnackbarEvent({ eventType: DetailsEventType.Failed });
			return;
		}

		try {
			setFeedbackSnackbarEvent({
				eventType: DetailsEventType.Downloading,
			});

			const singleTimesheetRequestBody: SingleTimesheetRequest = {
				timesheetID: currentTimesheet.id,
				includeSiteLogs: true, // currently always true
			};

			const response = await cloudFunctionApi.fetchSingleTimesheet(
				abortSignal,
				user,
				singleTimesheetRequestBody,
			);

			if (response === undefined || response.size < 100) {
				// no way any valid pdf is gonna be less than 100 bytes
				if (!abortSignal.aborted) {
					setFeedbackSnackbarEvent({
						eventType: DetailsEventType.Empty,
					});
				}
				return;
			}
			const modifiedDateString = format(
				currentTimesheet.weekEnding.toDate(),
				'dd-MM-yyyy',
			);

			downloadFile(
				response.blobUrl,
				`Timesheet - ${currentTimesheet.employee.name} - WE ${modifiedDateString}.pdf`,
			);
			setFeedbackSnackbarEvent({ eventType: DetailsEventType.Success });
		} catch (err) {
			setFeedbackSnackbarEvent({ eventType: DetailsEventType.Failed });
		}
	};

	const renderNoTimesheetDisplay = (): JSX.Element => {
		const sharedStyles = {
			textAlign: 'center',
			width: '100%',
		};

		return (
			<Stack
				justifyContent="center"
				sx={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}>
				{loading ? (
					<LoadingDots />
				) : (
					<>
						<FormatListBulletedOutlinedIcon
							sx={{
								...sharedStyles,
								fontSize: 100,
							}}
						/>
						{sortedTimesheets.length > 0 ? (
							<Typography variant="h4" sx={sharedStyles}>
								No Timesheet Selected
							</Typography>
						) : (
							<>
								<Typography variant="h4" sx={sharedStyles}>
									No Timesheet to display
								</Typography>
								<Typography variant="h6" sx={sharedStyles}>
									Change week in the overview table
								</Typography>
							</>
						)}
					</>
				)}
			</Stack>
		);
	};

	return (
		<Grid container justifyContent="space-between" spacing={2}>
			{!isSmallScreen && (
				<Grid
					item
					sm={3}
					sx={{
						height: 'calc(100vh - 170px)',
						overflow: 'hidden',
					}}>
					<TimesheetsStatusList
						timesheets={sortedTimesheets}
						timesheetId={
							currentTimesheet ? currentTimesheet.id : ''
						}
						setTimesheet={setTimesheet}
						isReview={usesTimesheetReviewTabName}
					/>
				</Grid>
			)}
			<Grid item xs={12} sm={12} md={9}>
				<Paper elevation={0}>
					<Stack
						direction="column"
						divider={<Divider flexItem sx={{ mb: 1 }} />}>
						<TimesheetActionsBar
							timesheet={currentTimesheet}
							timesheets={sortedTimesheets}
							setTimesheet={setTimesheet}
							action={action}
							handleEditClicked={
								currentTimesheet?.timesheetStatus !==
								TimesheetStatus.Submitted
									? null
									: (): void => setEditTimesheetOpen(true)
							}
							handlePDFClicked={downloadPDF}
						/>
						{currentTimesheet ? (
							<TimesheetDetails
								timesheet={currentTimesheet}
								activities={Object.values(activities)}
								notes={notes}
								siteLogs={siteLogs}
								defaultSiteLogURL={defaultSiteLogURL}
								loading={
									loading ||
									Object.values(itemsLoading).some(
										(item) => item,
									)
								}
								firebaseApi={firebaseApi}
							/>
						) : (
							renderNoTimesheetDisplay()
						)}
					</Stack>
				</Paper>
			</Grid>
			{currentTimesheet &&
				currentTimesheet.timesheetStatus ===
					TimesheetStatus.Submitted &&
				editTimesheetOpen && (
					<EditTimesheetDialog
						userDetails={userDetails}
						timesheet={currentTimesheet}
						activities={activities}
						siteLogs={siteLogs}
						dialogOpen={editTimesheetOpen}
						onClose={(): void => setEditTimesheetOpen(false)}
						selectPreviousTimesheet={selectPreviousTimesheet}
						navigateToTimesheet={navigateToTimesheet}
						setFeedbackSnackbarEvent={setFeedbackSnackbarEvent}
						firebaseApi={firebaseApi}
						notes={notes}
						cloudFunctionApi={cloudFunctionApi}
					/>
				)}
			{feedbackSnackbarEvent && (
				<Portal>
					<DetailsSnackbar
						event={feedbackSnackbarEvent}
						navigateToTimesheet={navigateToTimesheet}
						onClose={(): void => setFeedbackSnackbarEvent(null)}
					/>
				</Portal>
			)}
		</Grid>
	);
};
