import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { TabContext, TabPanel } from '@mui/lab';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	List,
	ListItem,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import { addDays, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import {
	Evacuation,
	Evacuee,
	Guest,
	Site,
	SiteLog,
	UserDetails,
	UserProps,
} from '../../constants/Common';
import { Timestamp } from '../../firebase/firebase';
import firebaseApi from '../../firebase/firebaseApi';
import { formatTime } from '../helpers/dateFormatters';
import { EvacuationsHistory } from './EvacuationsHistory';

type PeoplesInOutLog = Record<string, Record<string, SiteLog>>;
type PeoplesDetails = Record<string, Guest | UserDetails>;

export const Evacuations = ({ userDetails }: UserProps): JSX.Element => {
	const [tab, setTab] = useState('0');
	const [guestsLoading, setGuestsLoading] = useState<boolean>(true);
	const [usersLoading, setUsersLoading] = useState<boolean>(true);
	const [site, setSite] = useState<Site>();
	const [modalOpen, setModalOpen] = useState(false);
	const [totalOnLog, setTotalOnLog] = useState<null | number>(null);
	const [totalOnSite, setTotalOnSite] = useState<null | number>(null);
	const [inEvacuation, setInEvacuation] = useState<boolean>(false);
	const [evacuationFinished, setEvacuationFinished] =
		useState<boolean>(false);
	const [evacuationNote, setEvacuationNote] = useState<string>('');
	const [startTime, setStartTime] = useState<Date | null>(null);
	const [everyoneLogged, setEveryoneLogged] = useState<PeoplesDetails>({});
	const [usersLogged, setUsersLogged] = useState<PeoplesDetails>({});
	const [guestsLogged, setGuestsLogged] = useState<PeoplesDetails>({});
	const [inOutLogToday, setInOutLogToday] = useState<PeoplesInOutLog>({});
	const [markedWorkers, setMarkedWorkers] = useState<string[]>([]);
	const [unmarkedWorkers, setUnmarkedWorkers] = useState<string[]>([]);

	useEffect(() => {
		const getSite = async (): Promise<void> => {
			const site = await firebaseApi.getSite(userDetails.siteID);
			setSite(site);
		};
		getSite();
	}, [userDetails.siteID]);

	useEffect(() => {
		const startOfToday = startOfDay(new Date());
		return firebaseApi.siteLogsBySiteDateRangeSubscription(
			userDetails.siteID,
			startOfToday,
			addDays(startOfToday, 1),
			(logList: SiteLog[]) => {
				const logObject = {} as PeoplesInOutLog;
				for (const log of logList) {
					if (!Object.keys(logObject).includes(log.workerID)) {
						log.type === 'In'
							? (logObject[log.workerID] = { in: log })
							: (logObject[log.workerID] = { out: log });
					} else if (
						(log.type === 'In' && !logObject[log.workerID]['in']) ||
						(log.type === 'In' &&
							logObject[log.workerID]['in'].datetime <
								log.datetime)
					) {
						logObject[log.workerID]['in'] = log;
						if (
							logObject[log.workerID]['out'] &&
							log.datetime >
								logObject[log.workerID]['out'].datetime
						)
							delete logObject[log.workerID]['out'];
					} else if (
						log.type === 'Out' &&
						(!logObject[log.workerID]['out'] ||
							logObject[log.workerID]['out'].datetime <
								log.datetime)
					) {
						logObject[log.workerID]['out'] = log;
					}
				}
				setInOutLogToday(logObject);
			},
		);
	}, [userDetails.siteID]);

	useEffect(() => {
		const fetchGuests = async (): Promise<void> => {
			const guestIDs = Object.keys(inOutLogToday);
			const guestResult = await firebaseApi.getGuestsByIDs(guestIDs);
			setGuestsLogged(guestResult);
			setGuestsLoading(false);
		};
		fetchGuests();
	}, [inOutLogToday]);

	useEffect(() => {
		const fetchUsers = async (): Promise<void> => {
			const workersIDs = Object.keys(inOutLogToday);
			const userResult = await firebaseApi.getUsersByIDs(workersIDs);
			setUsersLogged(userResult);
			setUsersLoading(false);
		};
		fetchUsers();
	}, [inOutLogToday]);

	useEffect(() => {
		if (!guestsLoading && !usersLoading) {
			const everyone = { ...guestsLogged, ...usersLogged };
			setTotalOnSite(
				Object.values(everyone).reduce(
					(prevTotal, person) =>
						prevTotal + (person.signedIn ? 1 : 0),
					0,
				),
			);
			setTotalOnLog(Object.keys(everyone).length);
			setEveryoneLogged(everyone);
		}
	}, [guestsLogged, usersLogged, guestsLoading, usersLoading]);

	useEffect(() => {
		if (evacuationFinished && startTime !== null) {
			const everyoneOnSiteIDs = Object.keys(everyoneLogged);

			const newEvacuation: Evacuation = {
				startTime: Timestamp.fromDate(startTime),
				endTime: Timestamp.fromDate(new Date()),
				note: evacuationNote,
				site: userDetails.site,
				siteID: userDetails.siteID,
				siteCompany: site ? site.company : 'no company listed',
				siteCompanyID: site ? site.companyID : 'no companyID listed',
				totalPeople: everyoneOnSiteIDs.length,
				totalPeopleSafe: markedWorkers.length,
			};

			const guestIDs = Object.keys(guestsLogged);
			const newEvacuees = everyoneOnSiteIDs.reduce<
				Record<string, Evacuee>
			>((evauatedPeople, id) => {
				evauatedPeople[id] = {
					isGuest: guestIDs.includes(id),
					isSafe: markedWorkers.includes(id),
					name: everyoneLogged[id].displayName,
				};
				return evauatedPeople;
			}, {});

			firebaseApi.createEvacuation(newEvacuation, newEvacuees);

			setStartTime(null);
			setEvacuationNote('');
			setEvacuationFinished(false);
		}
	}, [
		evacuationFinished,
		evacuationNote,
		everyoneLogged,
		guestsLogged,
		markedWorkers,
		site,
		startTime,
		userDetails.site,
		userDetails.siteID,
	]);

	const handleTabChange = (_: React.SyntheticEvent, newTab: string): void => {
		setTab(newTab);
	};

	const handleEvacuationStart = (): void => {
		setMarkedWorkers(
			Object.keys(everyoneLogged).filter(
				(id) => !everyoneLogged[id].signedIn,
			),
		);
		setUnmarkedWorkers(
			Object.keys(everyoneLogged).filter(
				(id) => everyoneLogged[id].signedIn,
			),
		);
		setStartTime(new Date());
		setInEvacuation(true);
		setModalOpen(true);
	};

	const handleEvacuationEnd = (): void => {
		setInEvacuation(false);
		setEvacuationFinished(true);
		setTab('0');
	};

	const markWorker = (id: string, markSafe: boolean): void => {
		if (markSafe) {
			setMarkedWorkers([...markedWorkers, id]);
			const newUnmarkedWorkers = [...unmarkedWorkers];
			newUnmarkedWorkers.splice(newUnmarkedWorkers.indexOf(id), 1);
			setUnmarkedWorkers(newUnmarkedWorkers);
		} else {
			setUnmarkedWorkers([...unmarkedWorkers, id]);
			const newMarkedWorkers = [...markedWorkers];
			newMarkedWorkers.splice(newMarkedWorkers.indexOf(id), 1);
			setMarkedWorkers(newMarkedWorkers);
		}
	};

	const renderWorkerEvacuate = (id: string): JSX.Element => {
		const log = inOutLogToday[id];
		const markedSafe = markedWorkers.includes(id);

		return (
			<ListItem disableGutters key={id}>
				<Card sx={{ width: '100%' }}>
					<Box
						display="flex"
						flexDirection={{ xs: 'column', sm: 'row' }}
						alignItems="center">
						{(log.in && (
							<CardMedia
								component="img"
								image={log.in.url}
								alt="No Site Log Image data"
								sx={{
									width: { xs: '80px', sm: '100px' },
									height: { xs: '80px', sm: '100px' },
									marginBottom: { xs: 2, sm: 0 },
								}}
							/>
						)) ||
							(log.out && (
								<CardMedia
									component="img"
									image={log.out.url}
									alt="No Site Log Image data"
									sx={{
										width: { xs: '80px', sm: '100px' },
										height: { xs: '80px', sm: '100px' },
									}}
								/>
							))}
						<Box width="100%">
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={3}>
										<Typography variant="h6">
											{everyoneLogged[id].displayName}
										</Typography>
										<Typography variant="h6">
											{everyoneLogged[id].company}
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										sm={5}
										container
										spacing={1}>
										<Grid item xs={6}>
											<Typography variant="subtitle1">
												Most Recent Sign In
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant="subtitle1">
												{log.in
													? formatTime(
															log.in.datetime.toDate(),
													  )
													: 'No Site Sign In Data'}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant="subtitle1">
												Most Recent Sign Out
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant="subtitle1">
												{log.out
													? formatTime(
															log.out.datetime.toDate(),
													  )
													: 'No Site Sign Out Data'}
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										display="flex"
										justifyContent="center"
										alignItems="center">
										<Button
											variant="contained"
											fullWidth
											color={
												markedSafe
													? 'success'
													: 'neutral'
											}
											disabled={!inEvacuation}
											onClick={(): void =>
												markWorker(id, !markedSafe)
											}
											endIcon={
												markedSafe ? (
													<CheckCircle />
												) : (
													<RadioButtonUnchecked />
												)
											}>
											{markedSafe
												? 'Marked Safe'
												: 'Mark Safe'}
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Box>
					</Box>
				</Card>
			</ListItem>
		);
	};

	const dialog = (): JSX.Element => (
		<Dialog
			fullWidth
			maxWidth="xl"
			open={modalOpen}
			scroll="paper"
			fullScreen>
			<DialogTitle>
				<Typography variant="h5" component="div">
					Evacuation
				</Typography>
				<Typography variant="h6" component="div">
					{userDetails.site}
				</Typography>
				<Typography variant="h6" component="div">
					{new Date().toLocaleDateString()}
				</Typography>
			</DialogTitle>
			<DialogContent
				sx={{
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<TabContext value={tab}>
					<Box
						sx={{
							position: 'sticky',
							top: 0,
							backgroundColor: 'white',
						}}>
						<Tabs
							value={tab}
							onChange={handleTabChange}
							variant="scrollable"
							scrollButtons="auto"
							allowScrollButtonsMobile
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
								maxWidth: '100%',
							}}>
							<Tab label="Everyone" value="0" />
							<Tab label="Unmarked" value="1" />
							<Tab label="Marked Safe" value="2" />
							<Tab label="Notes" value="3" />
						</Tabs>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							overflowY: 'auto',
						}}>
						<TabPanel value="0">
							<List>
								{Object.keys(everyoneLogged).map((id) => {
									return renderWorkerEvacuate(id);
								})}
							</List>
						</TabPanel>
						<TabPanel value="1">
							<List>
								{unmarkedWorkers.map((id) => {
									return renderWorkerEvacuate(id);
								})}
							</List>
						</TabPanel>
						<TabPanel value="2">
							<List>
								{markedWorkers.map((id) => {
									return renderWorkerEvacuate(id);
								})}
							</List>
						</TabPanel>
						<TabPanel value="3">
							<TextField
								variant="outlined"
								value={evacuationNote}
								fullWidth
								multiline
								rows={6}
								placeholder="Make Notes"
								onChange={(event): void =>
									setEvacuationNote(event.target.value)
								}
							/>
						</TabPanel>
					</Box>
				</TabContext>
			</DialogContent>
			<DialogActions>
				<Typography sx={{ mr: 1 }}>
					Total Marked Safe {markedWorkers.length}/
					{Object.keys(everyoneLogged).length}
				</Typography>
				{inEvacuation ? (
					<Button
						color="primary"
						variant="contained"
						onClick={handleEvacuationEnd}>
						Finish Evacuation
					</Button>
				) : (
					<Button
						color="primary"
						variant="outlined"
						onClick={(): void => setModalOpen(false)}>
						Close
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			<Card
				sx={{
					width: '100%',
					marginBottom: 1,
				}}>
				<CardContent>
					<Grid container>
						<Grid item xs={6}>
							<Typography variant="h5">
								{userDetails.site} -{' '}
								{new Date().toLocaleDateString()}
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							display="flex"
							justifyContent="flex-end">
							<Button
								variant="contained"
								color="error"
								onClick={handleEvacuationStart}>
								Start Evacuation
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">
							Total Logged Today:{' '}
							{totalOnLog !== null ? totalOnLog : 'Loading...'}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">
							Currently On Site Today:{' '}
							{totalOnSite !== null ? totalOnSite : 'Loading...'}
						</Typography>
					</Grid>
				</CardContent>
			</Card>
			<EvacuationsHistory userDetails={userDetails} />
			{dialog()}
		</>
	);
};
